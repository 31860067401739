import type { FooterColumnLinks } from "~/components/Footer/types";
import type { CustomLinkRecord, FooterColumnRecord, PageLinkRecord } from "~/graphql/datocms/generated";
import type { LinkItem } from "~/models/links";

export function createFooterColumnLinks(data: Array<FooterColumnRecord>, localePath: (path: string) => string): Array<FooterColumnLinks> {
  return data.map((column) => {
    return {
      id: column.id,
      label: column.title,
      children: column.links.map((link) => {
        if (link._modelApiKey === "custom_link") {
          const customLink = link as CustomLinkRecord;
          return {
            id: customLink.id,
            label: customLink.text,
            to: customLink.customLink,
          };
        }
        if (link._modelApiKey === "page_link") {
          const pageLink = link as PageLinkRecord;
          return {
            id: pageLink.id,
            label: pageLink.text,
            to: getUrl(pageLink.pageLink!.slug, localePath),
          };
        }

        return { label: `Unknown link type ${link._modelApiKey}`, to: "#" };
      }),
    } as FooterColumnLinks;
  });
}

export function getLinkForType(link: any, localePath: (path: string) => string): LinkItem {
  if (!link) {
    return { id: "0", label: `Unknown link` };
  }

  if (link?._modelApiKey === "custom_link") {
    const customLink = link as CustomLinkRecord;

    return {
      id: customLink.id,
      label: customLink.text,
      to: customLink.customLink,
      target: getTarget(customLink.customLink || "/"),
    };
  }

  if (link?._modelApiKey === "page_link") {
    const pageLink = link as PageLinkRecord;

    return {
      id: pageLink.id,
      label: pageLink.text,
      to: getUrl(pageLink.pageLink!.slug, localePath),
      target: getTarget(getUrl(pageLink.pageLink!.slug, localePath)),
    };
  }

  return { id: "0", label: `Unknown link type ${link._modelApiKey}` };
}

export function getTarget(path: string) {
  try {
    if (path.startsWith("http")) {
      const pathUrl = new URL(path);
      const requestUrl = useRequestURL();

      if (pathUrl.host !== requestUrl.host) {
        return "_blank";
      }
    }

    return "_self";
  }
  catch (error) {
    return "_blank";
  }
}

export function getUrl(path: string, localePath: (path: string) => string) {
  if (path.indexOf(":") > 0) {
    return path;
  }

  return localePath(`/${path}`);
}
